import type { CompDefinition, BaseDataItem } from '@wix/thunderbolt-becky'

export type SocialComponentData = BaseDataItem & {
	isHttpsEnabled?: boolean
	urlChoice?: string
	urlFormat?: 'hashBang' | 'slash'
}

export type SocialComponentDefinition = CompDefinition<never, SocialComponentData, never, never>

export type SocialUrlComponentConfig = {
	useHttps: boolean
	forceMainPage: boolean
	useHashBangUrl: boolean
}

export type SocialUrlPageConfig = Record<string, SocialUrlComponentConfig>

export type SocialUrlSiteConfig = {
	mainPageId: string
	mainPageUrl: string
}

export enum SocialUrlComponent {
	WFacebookLike = 'WFacebookLike',
	WFacebookComment = 'WFacebookComment',
	FacebookShare = 'FacebookShare',
	VKShareButton = 'VKShareButton',
	WTwitterTweet = 'WTwitterTweet',
	PinterestPinIt = 'PinterestPinIt',
}
