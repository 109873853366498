import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { PageFeatureConfigSymbol, CurrentRouteInfoSymbol, SiteFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { ICurrentRouteInfo, IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'
import type { SocialUrlPageConfig, SocialUrlSiteConfig } from './types'
import { ComponentWillMount, ViewerComponent } from 'feature-components'
import { SocialUrlComponent } from './types'
import { name } from './symbols'

const SOCIAL_URL_PROP_NAME = 'socialUrl'

export const resolveSocialUrl = (currentUrl: URL, useHttps: boolean) => {
	const url = new URL(currentUrl.toString())

	url.protocol = useHttps ? 'https:' : 'http:'
	url.search = ''
	url.hash = ''

	return url.toString().replace(/\/$/, '')
}

export const resolveHashBangUrl = (
	forceMainPage: boolean,
	pageId: string,
	relativeUrl: string,
	baseUrl: string,
	useHttps: boolean
) => {
	const [, pageUriSeo, ...additionalUrlParts] = relativeUrl.split('/')
	const additionalUrl = additionalUrlParts.length ? `/${additionalUrlParts.join('/')}` : ''

	// Replace domain to the old wix.com domain
	const oldBaseUrls = baseUrl.replace('wixsite.com', 'wix.com')
	const url = new URL(`${oldBaseUrls}`)
	url.protocol = useHttps ? 'https:' : 'http:'

	if ((!forceMainPage && pageUriSeo) || !pageId) {
		url.hash = `!${pageUriSeo}/${pageId}${additionalUrl}`
	}
	return url.toString()
}

const socialUrlWillMount = (
	pageConfig: SocialUrlPageConfig,
	siteConfig: SocialUrlSiteConfig,
	urlHistoryManager: IUrlHistoryManager,
	currentRouteInfo: ICurrentRouteInfo
): ComponentWillMount<ViewerComponent> => {
	return {
		componentTypes: Object.values<string>(SocialUrlComponent),
		componentWillMount(component) {
			const mainPageUrl = new URL(siteConfig.mainPageUrl)
			const compId = component.id
			const { forceMainPage, useHttps, useHashBangUrl } = pageConfig[compId]

			const socialUrl = useHashBangUrl
				? resolveHashBangUrl(
						forceMainPage,
						currentRouteInfo.getCurrentRouteInfo()!.pageId,
						urlHistoryManager.getRelativeUrl(),
						siteConfig.mainPageUrl,
						useHttps
				  )
				: resolveSocialUrl(forceMainPage ? mainPageUrl : urlHistoryManager.getParsedUrl(), useHttps)
			component.updateProps({ [SOCIAL_URL_PROP_NAME]: socialUrl })
		},
	}
}

export const SocialUrl = withDependencies(
	[
		named(PageFeatureConfigSymbol, name),
		named(SiteFeatureConfigSymbol, name),
		UrlHistoryManagerSymbol,
		CurrentRouteInfoSymbol,
	],
	socialUrlWillMount
)
